import Airtable from "airtable";

const API_KEY =
  "patEg0eWZhsntqvvg.42a84484ef0d57acea2cf363c4cee034ce537ab6b68dd1216c5712dafb2f7fe8";
const BASE_ID = "appR1qqBVW4CbfHVK";
const TABLE_NAME = "tblYLN9drqZs2VQ5h";

Airtable.configure({
  apiKey: API_KEY,
});

const base = Airtable.base(BASE_ID);

export const fetchMerchantData = async (merchantCode) => {
  try {
    console.log("Fetching data for merchant code:", merchantCode);

    const records = await base(TABLE_NAME)
      .select({
        filterByFormula: `{Merchant Code}='${merchantCode}'`,
      })
      .firstPage();

    console.log("Received records:", records);

    if (records && records.length > 0) {
      const record = records[0].fields;
      const merchantData = {
        merchantCode: record["Merchant Code"],
        merchantName: record["Merchant Company Name"],
        productUrls: {
          UMPP_Application_URL: record["UMPP Application URL"],
          NMPP_Application_URL: record["NMPP Application URL"],
          GTT_Application_URL: record["GTT Application URL"],
          UMPP_Report_URL: record["UMPP Report URL"],
          NMPP_Report_URL: record["NMPP Report URL"],
          GTT_Report_URL: record["GTT Report URL"],
        },
      };
      console.log("Processed merchant data:", merchantData);
      return merchantData;
    } else {
      console.log("No records found for merchant code:", merchantCode);
      throw new Error("Merchant data not found");
    }
  } catch (error) {
    console.error("Error fetching data from Airtable:", error);
    console.error("Error details:", error.message);
    if (error.error) {
      console.error("Airtable error type:", error.error);
      console.error("Airtable error message:", error.message);
    }
    throw error;
  }
};
