import React from "react";
import ProductCard from "./ProductCard";
import styles from "../styles/ProductGrid.module.css";
const products = [
  {
    id: "UMPP",
    title: "UMPP",
    description: "Used Motorcycle Protection Plan",
    color: "#3498db",
    additionalLinks: [
      {
        text: "Pre-Inspection Check",
        url: "https://aeoninsurance.com.my/wp-content/uploads/2024/06/1f-UMPP_Pre-Inspection-Form-Motorcycle-V3.pdf",
      },
      {
        text: "Ownership Transfer",
        url: "https://aeoninsurance.com.my/wp-content/uploads/2024/06/Ownership-Transfer-Policy-Amendment.pdf",
      },
      {
        text: "Product Info",
        url: "https://umpp.aeonibrokersreward.com.my/thank-you-page/",
      },
    ],
  },
  {
    id: "NMPP",
    title: "NMPP",
    description: "New Motorcycle Protection Plan",
    color: "#2ecc71",
    additionalLinks: [
      {
        text: "Ownership Transfer",
        url: "https://aeoninsurance.com.my/wp-content/uploads/2024/06/Ownership-Transfer-Policy-Amendment.pdf",
      },
      {
        text: "Product Info",
        url: "https://nmpp.aeonibrokersreward.com.my/thank-you-page/",
      },
    ],
  },
  {
    id: "GTT",
    title: "GTT",
    description: "AIBM Group Takaful2u",
    color: "#e74c3c",
    additionalLinks: [
      {
        text: "Customer Consent",
        url: "https://aeoninsurance.com.my/wp-content/uploads/2024/04/CUSTOMER-CONSENT-GTT-product-revised-11-10-2023_final.pdf",
      },
      {
        text: "Product Info",
        url: "https://gtt.aeonibrokersreward.com.my/thank-you-page/",
      },
      {
        text: "Nominee Appointment (Optional)",
        url: "https://gtt.aeonibrokersreward.com.my/wp-content/uploads/2024/11/nominationform.pdf",
      },
    ],
  },
];
const ProductGrid = ({ productUrls }) => {
  return (
    <section className={styles.productGridWrapper}>
      <div className={styles.productGrid}>
        {products.map((product) => {
          const applicationUrl = productUrls[`${product.id}_Application_URL`];
          const reportUrl = productUrls[`${product.id}_Report_URL`];
          return (
            <ProductCard
              key={product.id}
              {...product}
              applicationUrl={applicationUrl || "#"}
              reportUrl={reportUrl || "#"}
            />
          );
        })}
      </div>
    </section>
  );
};
export default ProductGrid;
