import React from "react";

import styles from "../styles/ProductCard.module.css";



const ProductCard = ({

  title,

  description,

  applicationUrl,

  reportUrl,

  additionalLinks,

  color,

}) => {

  const isNMPP = title === "NMPP";



  return (

    <div

      className={`${styles.productCard} ${isNMPP ? styles.nmppCard : ""}`}

      style={{ "--card-color": color }}

    >

      <div className={styles.cardContent}>

        <h2 className={styles.title}>{title}</h2>

        <p className={styles.description}>{description}</p>

        <div className={styles.buttonContainer}>

          <a

            href={applicationUrl}

            className={`${styles.button} ${styles.applicationButton}`}

            target="_blank"

            rel="noopener noreferrer"

          >

            APPLICATION

          </a>

          <a

            href={reportUrl}

            className={styles.button}

            target="_blank"

            rel="noopener noreferrer"

          >

            REPORT

          </a>

          {additionalLinks &&

            additionalLinks.map((link, index) => (

              <a

                key={index}

                href={link.url}

                className={styles.button}

                target="_blank"

                rel="noopener noreferrer"

              >

                {link.text.toUpperCase()}

              </a>

            ))}

        </div>

      </div>

    </div>

  );

};



export default ProductCard;

