import React from "react";
import styles from "../styles/Hero.module.css";

const Hero = ({ merchantName, merchantCode }) => {
  return (
    <section className={styles.hero}>
      <div className={styles.imageOverlay}></div>
      <div className={styles.heroContent}>
        <h1 className={styles.merchantName}>{merchantName || "Welcome"}</h1>
        <p className={styles.merchantCode}>
          Merchant Code: {merchantCode || "N/A"}
        </p>
      </div>
    </section>
  );
};

export default Hero;
