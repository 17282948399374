import React, { useEffect, useState } from "react";
import styles from "../styles/Header.module.css";

const Header = () => {
  const [shrink, setShrink] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setShrink(true);
      } else {
        setShrink(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <header className={`${styles.header} ${shrink ? styles.shrink : ""}`}>
      <div className={styles.headerContent}>
        <img
          src="https://nmpp.aeonibrokersreward.com.my/wp-content/uploads/2024/08/cropped-logo.png"
          alt="Company Logo"
          className={styles.logo}
        />
      </div>
    </header>
  );
};

export default Header;
